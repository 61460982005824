<template>
  <div>
    <h3>
      Communication Template Search
      <hr />
    </h3>

    <button
      type="button"
      class="btn btn-primary mb-3 mt-4"
      @click="addTemplate"
    >
      Add New Template
      <i class="fa fa-plus-circle" />
    </button>
    <div class="card">
      <div class="card-header">
        <div class="card-title mb-0">Communication Templates</div>
      </div>
      <div class="card-body" v-show="templates.length > 0 && !loading">
        <div class="d-flex mb-2">
          <div class="align-self-center">Items Per Page:</div>
          <div class="ml-2">
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              aria-label="Items Per Page"
            ></b-form-select>
          </div>
        </div>
        <b-table
          ref="table"
          striped
          :fields="fields"
          :items="templates"
          :current-page="currentPage"
          :per-page="perPage"
          stacked="md"
          small
          class="mt-4"
          bordered
          sort-icon-left
        >
          <template v-slot:cell(id)="data">
            <button
              class="btn btn-primary btn-sm mr-2"
              @click="previewTemplate(data.item)"
            >
              Preview
            </button>
            <button
              class="btn btn-primary btn-sm mr-2"
              @click="modify(data.item.id)"
            >
              Modify
            </button>
            <button
              class="btn btn-primary btn-sm mr-2"
              @click="deleteTemplate(data.item)"
            >
              Delete
            </button>
          </template>
        </b-table>
        <div class="d-flex">
          <div class="mr-auto">
            Showing
            {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }} to
            {{ perPageDisplay }} of {{ totalRows }} entries
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
      <div
        class="card-body text-center"
        v-if="templates.length === 0 && !loading"
      >
        <div class="h4 my-3">Currently no templates for the tenant.</div>
      </div>
      <div class="card-body text-center" v-if="loading">
        <div class="my-3">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </div>
      <b-modal
        id="deleteModal"
        title="Delete Template"
        @ok="confirmDelete(templateToDelete.id)"
      >
        <div class="font-weight-bold">
          Are you sure you want to delete this template?
        </div>
        <br />
        <div class="font-weight-bold">
          Template Name:
        </div>
        <div>{{ templateToDelete.name }}</div>
        <br />
        <div>
          <button
            type="buttom"
            @click="previewTemplate(templateToDelete)"
            class="btn btn-primary btn-sm"
          >
            Preview Template
          </button>
        </div>
        <template #modal-footer="{ok, cancel}">
          <button type="buttom" @click="ok()" class="btn btn-primary">
            Confirm Delete
          </button>
          <button type="buttom" @click="cancel()" class="btn">
            Cancel
          </button>
        </template>
      </b-modal>
      <AdminHtmlPreviewModal
        title="Template Preview"
        :content="previewContent"
        ref="previewModal"
      />
    </div>
  </div>
</template>

<script>
import AdminCommunicationTemplateService from "@/services/admin/AdminCommunicationTemplateService.js";
import AdminHtmlPreviewModal from "@/components/admin/AdminHtmlPreviewModal";

export default {
  name: "AdminCommunicationTemplateManagement",
  title: "Admin - Communication Template Management",
  components: {
    AdminHtmlPreviewModal
  },
  data() {
    return {
      previewContent: "",
      templateToDelete: { name: "", content: "" },
      loading: false,
      templates: [],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      fields: [
        {
          key: "name",
          label: "Template Name",
          sortable: true
        },
        {
          key: "id",
          sortable: false,
          label: "Actions/Options",
          class: "actionsColumn"
        }
      ]
    };
  },
  computed: {
    totalRows() {
      return this.templates.length;
    },
    perPageDisplay() {
      if (this.perPage * this.currentPage > this.totalRows) {
        return this.totalRows;
      }
      if (this.totalRows > this.perPage) {
        return this.perPage;
      }
      return this.totalRows;
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async getTemplates() {
      this.loading = true;
      const service = new AdminCommunicationTemplateService(this.tenantId);
      const response = await service.getTemplates();
      if (response?.statusCode === "Success") {
        this.templates = response.data;
        this.loading = false;
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    addTemplate() {
      this.$router.push("/admin/communication-template-add").catch(() => {});
    },
    async confirmDelete(id) {
      this.cancel();
      this.loading = true;
      const service = new AdminCommunicationTemplateService(this.tenantId);
      const response = await service.deleteTemplate(id);
      if (response?.statusCode === "Success") {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-success",
          message: "Template has been deleted",
          layer: "admin"
        });
        this.cancel();
        this.getTemplates();
      } else {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    deleteTemplate(item) {
      this.templateToDelete = item;
      this.$bvModal.show("deleteModal");
    },
    modify(id) {
      this.$router
        .push(`/admin/communication-template-modify/${id}`)
        .catch(() => {});
    },
    previewTemplate(item) {
      this.previewContent = item.content;
      this.$refs.previewModal.show();
    }
  },
  created() {
    this.getTemplates();
  }
};
</script>

<style scoped>
.actionsColumn {
  width: 300px !important;
}
</style>
